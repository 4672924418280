export const upcommingEventsData = [
  {
    date: "27.01.2025 - 31.01.2025",
    title: "BootCamp Training onUnmanned Aerial Systems (Drone Electronics)",
    venue: "",
    time: "",
  },
  {
    date: "10.12.2024",
    title: "Human Rights Day",
    venue: "",
    time: "",
  },
  {
    date: "01.12.2024",
    title: "World Aids Day",
    venue: "",
    time: "",
  },
  {
    date: "14.11.2024",
    title: "Children's Day Celebration",
    venue: "",
    time: "",
  },
  {
    date: "01.11.2024 - 02.11.2024",
    title: "Closing Ceremony of Silver Jubilee",
    venue: "",
    time: "",
  },
  {
    date: "31.10.2024",
    title: "Rashtriya Ekata Divas",
    venue: "",
    time: "",
  },
  {
    date: "24.02.2024",
    title:
      "Two Day National Level-Online Workshop on Data analysis with Parametric and Non-Parametric tests through SPSS",
    venue: "",
    time: "",
  },
  {
    date: "24.02.2024",
    title: "Kabbadi (UG/PG Boys)",
    venue: "College Ground",
    time: "2pm - 4pm",
  },
  {
    date: "24.02.2024",
    title: "KhoKho (UG/PG Girls)",
    venue: "College Ground",
    time: "2pm - 4pm",
  },
  {
    date: "24.02.2024",
    title: "Biotechnology Seminar (UG)",
    venue: "Block - C Auditorium",
    time: "2pm - 4pm",
  },
  {
    date: "23.02.2024",
    title: "Biotechnology Workshop (UG)",
    venue: "Biotechnology Lab",
    time: "10am - 4pm",
  },
  {
    date: "22.02.2024",
    title: "Biotechnology Seminar (UG)",
    venue: "Block - C Auditorium",
    time: "10am - 1pm",
  },
  {
    date: "21.02.2024",
    title: "Sociology Seminar (UG)",
    venue: "Block - A Auditorium",
    time: "2pm - 4pm",
  },
  {
    date: "20.02.2024",
    title: "Volleyball Match (Boys PG)",
    venue: "College Ground",
    time: "2pm - 4pm",
  },
  {
    date: "19.02.2024",
    title: "Volleyball Match (Boys UG)",
    venue: "College Ground",
    time: "2pm - 4pm",
  },
  {
    date: "15.02.2024",
    title: "GREEN CAMPUS CELEBRATION",
    venue: "",
    time: "",
  },
  {
    date: "15.02.2024",
    title: "Silver Jubilee Inauguration",
    venue: "",
    time: "",
  },
  {
    date: "13.02.2024",
    title:
      "Talent Cornor HR services pvt. Ltd is going to conduct a Campus Recruitment Drive for MBA and MAPMIR students",
    venue: "",
    time: "",
  },
  {
    date: "12.02.2024",
    title: "Exploring Python Programming and its Diverse Application",
    venue: "Computer Lab 2",
    time: "11.15 AM",
  },
];
