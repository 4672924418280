import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
const NAACAqarResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");
  // const [currentRoute, setCurrentRoute] = useState("");
  const location = useLocation();
  // console.log(location.pathname);

  useEffect(() => {
    // setCurrentRoute(location.pathname);
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="AQAR" />

      {/* <Row className="justify-content-center">
        <Col md="4">
          <a
            href="https://drive.google.com/drive/u/1/folders/1n3sUGAeKaq37yK6uPS6zjYhjuVbpX367"
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">View All AQAR Documents</span>
          </a>
              </Col>
              
      </Row> */}

      <Row className="justify-content-center">
        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1CnI6fGr3H9_AqXI9OXZZs2tI_7y8N2Ez/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.4.1 - Institution obtains feedback (Stakeholders) Part-5
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1WBD6NIww3h4mGtv_L9dt4klPr9Q8oBd6/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.1.1 The Institution ensures effective curriculum delivery
              through a well planned and documented process_0001
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1BvqLBShnd0C5Oi8p5pU9bKR82K5vDYty/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.1.2 Adhere to Academic Calendar</span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1-fmQDuZZJgN1Sa0hBaPTEsFbag7l3L_q/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.1.3 Teachers of the Institution participate in Academic
              activities
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1SubLTWkHPahLJUHWqimp2DjuK1h0mmF1/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.2.1 No. of Electives Courses</span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1lfUplnYUPHF_gbHcMzFm8Zz6-ZIug9s8/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.2.2 Add-on-Certificate Programmes Offered during the Year
              2023-24
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/14KjmV7_EhHH6vs8nXJwGNjEKT7qAWGDb/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.3.1 Institute Integrate Cross Cutting Issues
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1_bi5o66E0V_OTvSXeEL-8JlGXdt29zOV/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.3.2 - MOU - Number of courses that include experiential learning
              through project workfield workinternship during the year Part-1
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1fYEvGmaTa2j1M0c7zEYoUcMXJq_Hl9vJ/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.3.2 - Number of courses that include experiential learning
              (Syllabus) Part-2
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/file/d/1_hU51JOM7-ZzeuuulGW6mCRcQQV6jDiT/view?usp=drive_link"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.3.2 - Number of courses that include experiential learning
              through project work field work Part-3
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1_nmVdqEwrypwAlBvWe9Rw8sICHD9-HC2&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.3.3 MBA SIP Upload Part-1</span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=15pMzfHFXIUR-6iAABQCxQE2kAbYdRNU6&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.3.3 MBA SIP Upload Part-2</span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1QbhIwW3l0NNoSy0isZnyoC9kNEklhxGb&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.3.3 MBA SIP Upload Part-3</span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1C8lUY2jbQ4ihz7TMDkkyUnNyPnRsb_lx&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.3.3 MCA SIP Upload Part-1</span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1eSWiq0ZvO3jVVzW-cNOCzGgKNa84gKdl&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">1.3.3 MCA SIP Upload Part-2</span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1khAY4NMsR1yuXdsxuczfUXpt7Le6Wg-2&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.4.1 - Institution obtains feedback (Action Taken Report)-3-10
              Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1i5eOe4I6KSfMzTg02NVx5p3PrNBwlJ78&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.4.1 - Institution obtains feedback (Consolidated)-1-2 Part-1
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1ZdizWXo3gAj07I8M7de7bqCqAMe62mJ_&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.4.1 - Institution obtains feedback (Summary) Part-3
            </span>
          </a>
        </Col>
        <br />
        <br />

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1b4J-0eJoD2n-v_AfPl5oUnyX5bR9xTEG&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              1.4.1 - Institution obtains feedback (Process) Part-4
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1oC1DbMvAoYYbF8gGptg7Pedahamxemhx&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.6.3 - Pass percentage of Students during the year
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1mKdu6zN-yJcKxZYr8R1IbVBZO-hONoAL&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.1.1 Student Enrollment in 2023-24 Part-1
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1uPC3s2jZ1QKCnY1kfkLYsApNUei0VnTO&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.2.1 Special Programmes for advanced learners and slow learners
              Part-2
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1jTsyzvrUnsC5K-mJ73_b38H_DkB07Ped&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">2.2.2 Student- Full time teacher ratio</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1z-G44DXmOWSQHaH-5_N6oW6pcChCBUgz&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.3.1 Student centric methods, such as experiential learning
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1M-OUxvSl68Us-9fVGaB3L75tL7V2TlBq&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.3.2 - Teachers use ICT enabled tools for effective
              teaching-learning process
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=15wvgy6YEuoKWTQymPbSwTbNpV_xy4M0X&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.3.3 - Ratio of mentor to students for academic and other related
              issues
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1Qltn8nR_tuV8Gk0IKW8s1Ruf0WszGWne&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">2.3.3.1 Menter-Mentee Ratio</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1fqhmbrdA6E2GqBTSTXAlY_PLZ_7IyZyL&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.4.3 - Number of years of teaching experience of full time
              teachers
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=13J-lTHUkItIwwUz2gCf0PYl_AWb6qbYz&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.5.1 - Mechanism of internal assessment is transparent and robust
              in terms of frequency and mode (Part)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=189trCDm0H3BqLmVs7bid6H1KOx12GQ9d&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.5.2 - Mechanism to deal with internal examination related
              grievances is transparent, time-bound and efficient
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1M5Kg_6FiwliYrqvbMpkpXJCRmzrq952v&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.6.1 - Teachers and students are aware of the stated Programme
              and course outcomes (CO) Part-1
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1KnbnoV3xQBGroPWF-nciZizMLAXlwFRp&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.6.1 - Teachers and students are aware of the stated Programme
              and course outcomes (PO) Part-2
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1tx-4dJLaOP_yC0WBKZnoh381YynMevDf&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              2.6.2 - Attainment of Programme outcomes and course outcomes
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1KKFBr6HyzNnTbDOMRYnMFi6c71jCT-Bk&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.4.2 - There are established systems and procedures for
              maintaining and utilizing physical, academic and support
              facilities - laboratory, library, sports complex, computers,
              classrooms etc.
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1dA5OqRkDJZ252-ZsDjtScURkPWRUiJqr&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.1.1 Grant Received from External Agencies
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1JCIeJINDas4cTSybk2gEmMv42ZbCvURJ&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.1.3 Total Number of Seminars-Conference-Workshop Conducted
              during the Year 2023-24_0001
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=15F7LAkZIplFEaQEs-1eBkwJiPnARe3jh&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">3.2 No. of Sanctioned Post</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1wTokvVAthpLOWB1-Lmv4KKSWjEDS_ntx&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">3.2.1 Paper Published per Teacher</span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1qkqRHuODFBBKfYIXUuoHjf1ObqRkdVys&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">3.2.1 Policy Guidelines for Research</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1HxnBnCvxeMHpZxX29ehdxRQb0v42iFBj&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.2.2- number of books and chapters edited volumes - books
              published and papers published in national - international
              conference proceedings
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1e4EUM5ecxDnNkNDRKxbpbHrW5iOzYQ3Z&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.3.1 - Extension activities are carried out in the neighborhood
              community, sensitizing students to social issues
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1yy6lg70YTFU8Hyy_BzXvKirJ3zbHvBtL&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.3.2 Number of Awards and Recognitions
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1YURTTm7Sxpd21NROIjjc6yoR75SA8IDE&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.3.4.1 - Extension activities are carried out in the neighborhood
              community, sensitizing students to social issues (Part-1)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1oMlUdGSCMwB0crIbZZArC46y0c_DYkPZ&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              3.3.4.1 Total number of Students Participating in Extension
              Activities (Part-2)
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=17Dy21kXufhokbXLDMU7bQuqEsA5JwIYa&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">3.4.1 Collaboration</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=14FSuuPGxRuWxSvm9bU9WuRwNw8gQIhuE&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.1.1 - The Institution has adequate infrastructure and physical
              facilities for teaching- learning. viz., classrooms, laboratories,
              computing equipment etc._0001
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1V-FL2xDULOxMuF3zzCu-AHtlcMYT7lfG&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.1.2 - The Institution has adequate facilities for cultural
              activities, sports, games (indoor, outdoor), gymnasium, yoga
              centre etc._0001
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1d0C1-rn980ZsHrguWjyXF2AGTpMO_lh0&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.1.3 - Number of classrooms and seminar halls with ICT- enabled
              facilities such as smart class, LMS, etc.
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=19AlM3FxdL-kI2mgqHYgvdnqspa8Miwz8&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.2.1 - Library is automated using Integrated Library Management
              System (ILMS)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1PoxMCcrVvlR0PdootnAi0x8pZ0RMC8fY&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.2.2 - The institution has subscription for the following
              e-resources
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1t9JyW0yObwVFkHK-xz4jodZUZwtbPllH&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.2.3.1 - Annual expenditure of purchase of books e-books and
              subscription
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=11PctCkB1OJbpOHHU-M2g_xTz-1ovJD-0&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.2.4 - Number per day usage of library by teachers and students
              (Part-A)
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1DPIvMc9ZivOhPe469cLLV0mnlrmT8OAf&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.2.4 Login Data for Online Access (Part-B)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1UbbESBPDTLx6xr7r9cskE0fBfKP5rK1u&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.3.1 - Institution frequently updates its IT facilities including
              Wi-Fi
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1L90GXMvpUxgIyQvT025W0ki9zQK5a0zw&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">4.3.2 - Number of Computers (Part-A)</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1D8Yl3mi2_T3HGamHPqOvPZ0IUCsoBAhF&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">4.3.2 Student Computer Ratio (Part-B)</span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1LVOD6-LHL_V9OvXqr_mifUaGyuXQpUPH&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.3.3 - Bandwidth of internet connection in the Institution
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1rTstE2-mF9t95kYniJghThnxFc3XYd_d&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              4.4.1 - Expenditure incurred on maintenance of infrastructure
              (physical and academic support facilities) excluding salary
              component during the year
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1rkYjMW4kmH3IFCbTNG0ooYzSptOkT8CV&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.1.1 - Number of students benefited by scholarships (Part-1)
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=17imvaW9pNPTnLldRXdZq7KjSV8yXIibq&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.1.1 - Number of students benefited by scholarships (Part-2)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=171ks68b_gabdS_KVN5fXn8zuNDZz2ZJ6&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.2 - Number of Students benefitted through Free Ship
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1eMIIc46f9MplIDxNMMMfkTHvEdGatOc1&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.2.1 - Total number of students benefited by scholarships, free
              ships
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1we5f5MRYW84-oHqKk4TXMU2dKu_cG6nu&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.3 - Capacity building and skills enhancement initiatives taken
              by the institution
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1B5iYE4y5k64fsVIrNGUAUGWNH2o3tTe-&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.4 - Number of Students benefitted by Guidance for Competitive
              Examination
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1wfJg4fv5gRrkkMB9JbI0sujeVe0N07GP&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">5.4.2 Audited Statement (Part-2)</span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1pSNfravS2e-yL31gJCTsUCQGJHMD6Nhp&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.1.5 The Institution has a Transparent Mechanism Final
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1b3IaDay9jKcA9xZ-uosl5NU02SK6MSrI&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.2.1 - Number of placement of outgoing students during the year -
              MBA Part-3
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1TVQNiMkrMKs6j2LIcmq7wr7ZUb06j-8P&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.2.1 - Number of placement of outgoing students during the year
              -MCA Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1QKuB8z6K_IYQtiMlcT87iINRQi_TRHSh&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.2.1 - Number of placement of outgoing students during the year
              Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1-5YzANVDKEcSuHegS7QiIJ1XRbkpD18u&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.2.2 - Number of students progressing to higher education during
              the year
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=18LiXzEQ96AogfZzsVfNSTxcY18Rj6Msz&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.2.3 Number of Students Qualifying in State-National Level
              Examinations
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=15kv8hBJFGpJLM4isYFHwtcQp17XyUt5S&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.3.1 Number of Awards-Medals for Outstanding Performance
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1gXV125HcmNKwA8yVKGSIxn6MTShgRUBu&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.3.2 - Institution facilitates students’ representation and
              engagement in various administrative, co-curricular and
              extracurricular activities
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1muRMMeFd5maa6-QEC7sRm6df2pzR78rl&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.3.3 - Number of sports and cultural events/competitions in which
              students of the Institution
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=113a-vUlcjGa92pmNZQRe76XiW_J1GnHM&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">5.4.1 Alumni Engagement</span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1t74IoeBSPKQYAth8L4nF-ojhFQ2aYe1u&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              5.4.2 Alumni Contribution during the Year 2023-24 (Part-1)
            </span>
          </a>
        </Col>

        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1c8ELAJlbk4kTekB00mDXcQW2BqiZOiPX&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">6.5.3 Quality Assurance Initiatives</span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=18qlrKL6-gJSCsF808RtUQReFKZQb-nQ6&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.1.1 - The governance of the institution is reflective of and in
              tune with the vision and mission of the institution
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1KF61nw2c9megTzpDrweNyQKieVQ_igYm&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.1.2 - The effective leadership is visible in various
              institutional practices such as decentralization and participative
              management
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1rnXdzNtYmDWtncUOjo1-UXT_XKDJk6en&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.2.1 - The institutional Strategic- perspective plan is
              effectively deployed_0001
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1E5alc8nM0FBQAwJ6Kvh2IavPXi747LA7&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.2.2 - The functioning of the institutional bodies is effective
              and efficient as visible from policies, administrative setup,
              appointment and service rules, procedures, etc._0001
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1xva5-Xa9Vn56sp_GDWzCRckwDYi_Dxor&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">6.2.3 Implementation of e-Governance</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=193UEZZ1lIRq8PhtNclCEDu5eVYJYSTPl&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.1 - The institution has effective welfare measures for
              teaching and non-teaching staff
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1Um2B35LbxeXz9tDfQmZxmXbkGxveNTUY&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.2 - Number of teachers provided with financial support to
              attend conferences workshops
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1Y0_HFqe_QMFaLGDnI-LDQ66__ygpuX4C&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.3 - Number of professional development administrative training
              programs (RDC) (Part-1)
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=16SKp2NDkNyx9ORnVKjigqxtWbGmIxUPU&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.3 - Number of professional development administrative training
              programs (Part-2)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1P5tVxtA-trl3nfjW24XP2Ajuo2XhnbTu&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.3.1 - Total number of professional development administrative
              training Programmes (Similar Centre)
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1WCSewR43bMEXhXF9yXbMz9089ROlYtW1&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.4 - Number of teachers undergoing online Program (Annual IQAC
              Report)
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1-W_g_GvF8HzRMH8LUfqTEnqyJDyz6vpV&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.3.4 Total No of Teachers attending professional development
              programme
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1KpYdjf1_jGa0B6dQc_w5iDBlptQJWv1V&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">6.3.5 Appraisal Report</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1FgA_gGqtvGL9sxYMVC8aHC_YrD7v9o0T&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.4.1 - Institution conducts internal and external financial
              audits
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1vUOGsJ27tDk7GKyPJlI7dcAVzXwyXAi-&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.4.2.1 - Total Grants received from non-government bodies,
              individuals
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1_CO66tAH7hQ_SSyNQpkK2uU6M4rB0LQ8&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.5.1 - Internal Quality Assurance Cell (IQAC) has contributed
              significantly for institutionalizing the quality assurance
              strategies and processes
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1P6Dn4eA8B35E-aJeMGxAWkcYuTLUUHnC&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              6.5.2 - The institution reviews its teaching learning process,
              structures & methodologies of operations and learning outcomes
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1UTXzFVFx95FPmAuFru6H0F9J-4h-CBP7&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.3.1 - Portray the performance of the Institution in one area
              distinctive (Seminar, Addon, Competitive Exam) Part-2
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1OtWODKnF0z6H7QolVe7Otv3QUBwmaRx2&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.1 - Measures initiated by the Institution for the promotion of
              gender equity during the year
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1_4gmP_AK00xUPqIeYnMk4QeEkVQaZzOX&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.1 Annual gender sensitization action plan
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1B-aB-zyTIdaZxpfwU6kOEVQI72H2c0G1&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">7.1.1 Gender Audit</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=17Nr3FnYDkMy1KBL7Ms7FHObgxaSVRH-3&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.2 - The Institution has facilities for alternate sources of
              energy and energy conservation measures
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1cJo0MkwwPba5d2RyYjMKFjkB6X5E2Qjm&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.3 - Describe the facilities in the Institution for the
              management of the following types of degradable and non-degradable
              waste_0001
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1JSfWf5byhqNRpZbhCjmqx5E1dCr-O7qu&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.4 - Water conservation facilities available in the
              Institution_0001
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=12FmXPLb0gwt67JCYP9egUARF9Dmfqsp5&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">7.1.5 - Green campus initiatives_0001</span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1yxNUWm6Twbt14aQyDUNTKzgFMl1c0HBM&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.6 - Quality audits on environment and energy (Extract of Green
              Audit) Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=14FyaCG8EOt1E5EaNUOKoBrxv6kkSCmw_&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.6 - Quality audits on environment and energy (Green Audit
              Report) Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1hXFzr8NFC0HuBdl3NPTvNPxQopokpVSl&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.6 - Quality audits on environment and energy are regularly
              undertaken by the institution (Award) Part-3
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1MbxOkegP9tK2JTybzyG0ELiVz1ATwe3B&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.6 - Quality audits on environment and energy are regularly
              undertaken by the institution (Certificate) Part-5
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1X28amih1uanLjSGSE0BzqUONuGvncSw2&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.7 - The Institution has disabled-friendly, barrier free
              environment (Photos) Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1QeZIs0HlQYVxroNnkjv7UhOVpFAEXtqv&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.7 - The Institution has disabled-friendly, barrier free
              environment (Software) Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1A6nl41NiwSDrcJfB77ZJsWfYtpjivuEX&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.7 - The Institution has disabled-friendly, barrier free
              environment Part-3
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1JbLBDA1mrEmYPmLiZGRNh-bq5G49Bm4k&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.8 - Describe the Institutional efforts -initiatives in
              providing an inclusive environment Part-1
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1aRdtbapHAXFup1hVqac-7LbFOIDENR0a&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.8 - Describe the Institutional effortsinitiatives in providing
              an inclusive environment Part-2
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=16iPWCbFMi2gHTCSGipqZ5EovH9bgJ9wA&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.9 - Sensitization of students and employees of the Institution
              to the constitutional obligations values, rights, duties and
              responsibilities of citizens
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1-8A4tN6Zzi5ZjpH8PQ3_181DohFI8fUE&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.10 - The Institution has a prescribed code of conduct
              (Governance) Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=17DnfDzpU6WA3VM3JoVvQPOLhwTWg2FSa&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.10 - The Institution has a prescribed code of conduct (Service
              Rule) Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1YxCVNjVAxT0rfBgBzHgNeHCKw9Y3pzgT&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.10 - The Institution has a prescribed code of conduct for
              students Part-3
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1-fONwfsSicT3NlpDpqBCViro6rPgEtD7&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.11 Annual report of the celebrations and commemorative events
              Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1cwGBo5zn_c2cLn3Sm5OUhEnRT_px1bZY&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.1.11 Annual report of the celebrations and commemorative
              events(Photos) Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1Sjgv-X_w7bUeJYwoqqj4tYFn3-kEk4_1&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.2.1 - Describe two best practices successfully implemented
              (Academic Audit) Part-2
            </span>
          </a>
        </Col>
        <br />
        <br />
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=17GBrAyaA0Emrrnoy26Yt0dO9IHwm4dmc&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.2.1 - Describe two best practices successfully implemented (Best
              Practices) Part-1
            </span>
          </a>
        </Col>
        <Col md="6">
          <a
            href="https://drive.google.com/open?id=1fYzOl_TNMVCNIc1OzuSexJiQcQ-i_Bnh&usp=drive_copy"
            className="d-flex align-items-center justify-content-start text-uppercase text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">
              7.3.1 - Portray the performance of the Institution in one area
              distinctive (Placement) Part-1
            </span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default NAACAqarResponsive;
